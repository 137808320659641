
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateCreateRideIntent from '@/components/templates/rides/TemplateCreateRideIntent.vue';

export default Vue.extend({
  name: 'CreateRide',
  components: { TemplateCreateRideIntent },
  metaInfo: {
    title: ` - ${i18n.t('tabs.ride')}`,
  },
});
