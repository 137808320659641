enum WizardErrorCodesEnum {
  GENERIC_MESSAGE = 'Es ist ein Problem aufgetreten. Bitte überprüfe deine Eingabe.',
  TIME_SLOT_OVERLAP = 'Konflikt mit anderem Fahrtwunsch im selben Zeitraum',
  TIME_INTERVAL_TOO_SMALL = 'Difference between start and end times is not sufficient according to our map information.',
  DISTANCE_TOO_SMALL = 'Origin is within walking distance of destination.',
  CONFLICT = 'conflict',
  TOO_SMALL = 'too_small',
  WALKING_DISTANCE = 'walking_distance'
}

export const mapErrCodeToTranslation = {
  [WizardErrorCodesEnum.GENERIC_MESSAGE]: 'rideIntent.errors.genericError',
  [WizardErrorCodesEnum.TIME_SLOT_OVERLAP]: 'rideIntent.errors.timeSlotOverlap',
  [WizardErrorCodesEnum.TIME_INTERVAL_TOO_SMALL]: 'rideIntent.errors.timeIntervalTooSmall',
  [WizardErrorCodesEnum.DISTANCE_TOO_SMALL]: 'rideIntent.errors.distanceTooSmall',
};

export default WizardErrorCodesEnum;
