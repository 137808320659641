
import Vue, { PropType } from 'vue';
import StepStatuses from '@/enums/stepper/StepperEnum';

export interface Step {
  title: string;
  componentName: string;
  status: StepStatuses;
}

interface AtomStep {
  index: number;
  title: string;
  steps: number;
  isCurrent: boolean;
  isCompleted: boolean;
  jumpBacktoStep(index: number): void;
}

export default Vue.component<AtomStep>('AtomStep', {
  functional: true,
  props: {
    title: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    steps: {
      type: Number,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    jumpBacktoStep: Function as PropType<(index: number) => void>,
  },
});
