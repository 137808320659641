
import Vue, { PropType } from 'vue';
import StepStatuses, { StepperSteps } from '@/enums/stepper/StepperEnum';
import AtomStep, { Step } from '@/components/atoms/AtomStep.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export default Vue.extend({
  name: 'MoleculeStepper',
  components: {
    AtomStep,
    // eslint-disable-next-line vue/no-unused-components
    AtomSvgIcon,
  },
  data: () => ({}),
  props: {
    steps: {
      type: Array as PropType<Step[]>,
      required: true,
    },
    jumpBacktoStep: Function as PropType<(id: StepperSteps, index: number) => void>,
  },
  methods: {
    isCurrent(step: Step): boolean {
      return step.status === StepStatuses.CURRENT;
    },
    isCompleted(step: Step): boolean {
      return step.status === StepStatuses.COMPLETED;
    },
    handleJumpBackToIndex(id: StepperSteps, index: number) {
      this.$props.jumpBacktoStep(id, index);
    },
  },
  computed: {
    getNumberOfSteps(): number {
      return this.$props.steps.length;
    },
    getTitleStyles(): { width: string } {
      return { width: `${100 / this.getNumberOfSteps - 1}%` };
    },
  },
});
