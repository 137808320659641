
import Vue from 'vue';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RideIntentCreationState, Trip } from '@/store/modules/ride-intent/state';
import MoleculeStepper from '@/components/molecules/stepper/MoleculeStepper.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismPhoneValidation, { PHONE_VALIDATION_MODAL_ID } from '@/components/organisms/phone-validation/OrganismPhoneValidation.vue';
import { StepperSteps } from '@/enums/stepper/StepperEnum';
import { postIntent, updateIntent, updateRecurrence } from '@/api/ride-intent/rideIntentApi';
import SwitchEnum from '@/enums/switch/SwitchEnum';
import WizardErrorCodesEnum from '@/enums/errorCodesEnum/WizardErrorCodesEnum';
import RideIntentsTypeEnum from '@/enums/rides/RideIntentsTypeEnum';
import RideDetailsType from '@/enums/rides/RideDetailsTypeEnum';
import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';
import { toast, ToastVariants } from '../../atoms/AtomToast';

export default Vue.extend({
  components: {
    AtomButton,
    MoleculeStepper,
    OrganismPageSubHeader,
    OrganismPhoneValidation,
    OrganismRoleSelection: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismRoleSelection.vue' /* webpackChunkName: "OrganismRoleSelection" */
      ),
    OrganismOutboundTrip: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismOutboundTrip.vue' /* webpackChunkName: "OrganismOutboundTrip" */
      ),
    OrganismReturnTrip: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismReturnTrip.vue' /* webpackChunkName: "OrganismReturnTrip" */
      ),
    OrganismOutboundDateSelection: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismOutboundDateSelection.vue' /* webpackChunkName: "OrganismOutboundDateSelection" */
      ),
    OrganismReturnTripDateSelection: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismReturnTripDateSelection.vue' /* webpackChunkName: "OrganismReturnTripDateSelection" */
      ),
    OrganismCarSelection: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismCarSelection.vue' /* webpackChunkName: "OrganismCarSelection" */
      ),
    OrganismExtras: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismExtras.vue' /* webpackChunkName: "OrganismExtras" */
      ),
    OrganismSuccessfulCreation: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismSuccessfulCreation.vue' /* webpackChunkName: "OrganismSuccessfulCreation" */
      ),
    OrganismSummary: () =>
      import(
        // eslint-disable-next-line comma-dangle
        '../../organisms/ride-intent/OrganismSummary.vue' /* webpackChunkName: "OrganismSummary" */
      ),
  },
  name: 'TemplateCreateRideIntent',
  data: () => ({
    ButtonVariant,
    showFixedNav: true,
    intentId: null as string|null,
    hasValidPhone: true,
    isLoading: false,
    createdIntents: [],
  }),
  props: {},
  methods: {
    ...mapActions('rideIntent', [
      'resetState',
      'hydrateWizard',
      'jumpToStep',
      'rideIntentCreationSuccessful',
      'selectRole',
      'setDetour',
      'setOffersPayment',
      'setClaimsPayment',
      'setErrorCode',
      'setOutboundEarliestDeparture',
      'setOutboundLatestArrival',
      'setReturnTripEarliestDeparture',
      'hydrateWizardWithInvite',
      'addStartPointLocation',
      'addEndPointLocation',
      'validateOutboundTripRoute',
    ]),
    ...mapActions('poi', ['fetchPoi']),
    ...mapActions('rides', ['fetchIntents']),
    ...mapActions('car', ['fetchAllCars']),
    handleScroll() {
      const footerElement = document.querySelector('.footer');
      if (footerElement) {
        if (footerElement.getBoundingClientRect().top <= window.innerHeight) {
          this.showFixedNav = false;
        } else {
          this.showFixedNav = true;
        }
      }
    },
    nextStep() {
      const next = this.getNext;
      this.jumpToStep(next);
      if (
        (this.getErrorCode === WizardErrorCodesEnum.CONFLICT) ||
        (this.getErrorCode === WizardErrorCodesEnum.TOO_SMALL) ||
        (this.getErrorCode === WizardErrorCodesEnum.WALKING_DISTANCE)
      ) {
        this.setErrorCode('');
      }
    },
    async nextAction() {
      if (this.getCurrentStepIndex !== this.activeSteps.length - 1) {
        this.nextStep();
      } else if (this.intentId !== null) {
        this.isLoading = true;
        await this.updateRideIntent();
      } else {
        this.isLoading = true;
        await this.postRideIntent();
      }
      this.isLoading = false;
    },
    async postRideIntent() {
      const { status, data: intent } = await postIntent(
        this.getUserId,
        this.$store.state.rideIntent,
        this.getReturnTripStatus === SwitchEnum.ON,
      );
      if (status === 201) {
        this.rideIntentCreationSuccessful('http://my-url.com/32143fsfvscs');
        this.createdIntents = intent;
      } else {
        this.setErrorCode(status.data?.error?.jsonDetail[0].constraint);
      }
    },
    async updateRideIntent() {
      if (this.intentId) {
        let status;
        const { query: { withSequence }, path } = this.$router.currentRoute;
        const { outboundTrip, returnTrip } = this.$store.state.rideIntent as { outboundTrip: Trip; returnTrip: Trip };
        const { rideIntent } = this.$store.state;
        const { getUserId, intentId, isOutwardInThePast } = this;

        const intention = this.getIntentType(path, !!withSequence);

        if (
          intention.includes(RideIntentsTypeEnum.SINGLE_INTENT)
          && intention.includes(RideIntentsTypeEnum.SEQUENCE)
        ) {
          if (!isOutwardInThePast) status = await updateIntent(getUserId, rideIntent, outboundTrip);
          status = await updateIntent(getUserId, rideIntent, returnTrip);
        } else if (
          intention.includes(RideIntentsTypeEnum.RECURRENT)
          && intention.includes(RideIntentsTypeEnum.SEQUENCE)
        ) {
          if (!isOutwardInThePast) status = await updateRecurrence(getUserId, rideIntent, outboundTrip);
          status = await updateRecurrence(getUserId, rideIntent, returnTrip);
        } else if (intention.includes(RideIntentsTypeEnum.SINGLE_INTENT)) {
          status = await updateIntent(getUserId, rideIntent, outboundTrip);
        } else if (intention.includes(RideIntentsTypeEnum.RECURRENT)) {
          status = await updateRecurrence(getUserId, rideIntent, outboundTrip);
        }

        if (status === 200) {
          this.$router.push({
            name: 'RideDetails',
            params: { id: intentId, type: RideDetailsType.UNMATCHED_RIDE, justUpdated: 'true' },
          });
        } else {
          toast(
            this.$bvToast,
            status.data?.error?.text || this.$t('rideIntent.messages.error').toString(),
            ToastVariants.DANGER,
          );
        }
      }
    },
    previousStep() {
      const prev = this.getPrevious;
      this.jumpToStep(prev);
    },
    jumpBackToStep(stepId: StepperSteps, index: number) {
      const currentIndex = this.getCurrentStepIndex;
      if (index < currentIndex) {
        this.jumpToStep(stepId);
      }
    },
    getIntentType(path: string, withSequence: boolean): RideIntentsTypeEnum[] {
      const editMode = [] as RideIntentsTypeEnum[];

      if (withSequence) editMode.push(RideIntentsTypeEnum.SEQUENCE);

      if (path.indexOf('recurrences') !== -1) editMode.push(RideIntentsTypeEnum.RECURRENT);
      else editMode.push(RideIntentsTypeEnum.SINGLE_INTENT);

      return editMode;
    },
    applyDefaultSettings() {
      const { params: { id } } = this.$router.currentRoute;
      if (!id) {
        this.selectRole(this.rideSettings.role);
        this.setDetour(this.rideSettings.maxDetourMinutes);
        this.setOffersPayment(this.rideSettings.offersPayment);
        this.setClaimsPayment(this.rideSettings.claimsPayment);
      }
    },
    getButtonLabel() {
      if (this.intentId && this.currentStep.id === StepperSteps.OVERVIEW) {
        return this.$t('rideIntent.wizard.editRideIntent').toString();
      }
      return this.$t(this.currentStep.nextBtnName).toString();
    },
  },
  computed: {
    ...mapGetters('rideIntent', ['getErrorCode', 'isOutwardInThePast']),
    ...mapGetters('rideIntent', {
      activeSteps: 'getActiveSteps',
      currentStep: 'getCurrentStep',
      getNext: 'getNext',
      getPrevious: 'getPrevious',
      getCurrentStepIndex: 'getCurrentStepIndex',
      getReturnTripStatus: 'getReturnTripStatus',
    }),
    ...mapState('rideIntent', ['createdRideIntent']),
    ...mapGetters('poi', ['getPoi']),
    ...mapGetters('car', ['getAllCars']),
    ...mapGetters('rides', ['getIntents']),
    ...mapGetters('user', ['b2cFree', 'getUserId', 'isPhoneVerified', 'hasPhoneNumber', 'sms', 'rideSettings', 'site']),
    displayWizard(): boolean {
      return this.createdRideIntent.state === RideIntentCreationState.NOT_TRIED;
      // return false;
    },
    rideCreationSuccessful(): boolean {
      return this.createdRideIntent.state === RideIntentCreationState.TRIED_AND_SUCCEEDED;
    },
    rideCreationFailed(): boolean {
      return this.createdRideIntent.state === RideIntentCreationState.TRIED_AND_FAILED;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    this.resetState();

    const { getIntentType, $router, getPoi, getAllCars, getIntents, getUserId } = this;
    const {
      query: { withSequence, sequenceId, departureDate, inviteId, isQuickCreation },
      params: { id, locale },
      path,
    } = $router.currentRoute;

    if (departureDate) {
      this.setOutboundEarliestDeparture({ value: moment(departureDate as string).add(7, 'hours') });
      this.setReturnTripEarliestDeparture({ value: moment(departureDate as string).add(17, 'hours') });
    }

    if (this.hasPhoneNumber && !this.sms?.verified) {
      this.$bvModal.show(PHONE_VALIDATION_MODAL_ID);
    }

    if (getPoi.length === 0) {
      this.fetchPoi({ ownerId: this.getUserId, type: PoiTypesEnum.USER });
      if (!this.b2cFree) this.fetchPoi({ type: PoiTypesEnum.GLOBAL });
    }
    if (getAllCars.length === 0) this.fetchAllCars();
    if (getIntents.length === 0) await this.fetchIntents(getUserId);
    if (id) {
      this.intentId = id;
      await this.jumpToStep(StepperSteps.OVERVIEW);
      this.hydrateWizard({ id, editIntention: getIntentType(path, !!withSequence), sequenceId }).then(() => {
        this.jumpToStep(StepperSteps.OVERVIEW);
      }).catch(() => {
        $router.push({ path: `/${locale}/create/ride` });
      });
    } else if (inviteId) {
      this.hydrateWizardWithInvite(inviteId).then(() => {
        this.jumpToStep(StepperSteps.OVERVIEW);
      }).catch(() => {
        $router.push({ path: `/${locale}/create/ride` });
      });
    } else if (isQuickCreation) {
      this.applyDefaultSettings();

      const { to, from, date } = window.intentInfo;

      if (date) this.setOutboundEarliestDeparture({ value: moment(date) });
      if (date) this.setOutboundLatestArrival(moment(date));
      if (from) this.addStartPointLocation(from);
      if (to) this.addEndPointLocation(to);
      if (to && from) this.validateOutboundTripRoute();
    } else {
      this.applyDefaultSettings();
    }
  },
  watch: {
    isPhoneVerified() {
      if (!this.hasPhoneNumber || (this.hasPhoneNumber && this.sms.verified)) {
        this.hasValidPhone = false;
        this.$bvModal.hide(PHONE_VALIDATION_MODAL_ID);
        return;
      }

      if (this.hasPhoneNumber && !this.isPhoneVerified) {
        this.$bvModal.show(PHONE_VALIDATION_MODAL_ID);
      }
    },
    rideSettings() {
      this.applyDefaultSettings();
    },
  },
});
